import pl from './lang/pl.json'
import en from './lang/en.json'
export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'pl',
  locales: ['pl', 'en'],
  defaultLocale: 'pl',
  messages: {
    pl,
    en
  }
}))
